import React from 'react';
import { GatsbyImage, getSrc, getImage } from 'gatsby-plugin-image';
// eslint-disable-next-line no-unused-vars
import tw, { styled } from 'twin.macro';
import BlockContent from '@sanity/block-content-to-react';
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import 'react-awesome-slider/dist/styles.css';
import '../utils/awesome-slider-override.css';
import { ImageSlide } from '../entities/image-slide';

import { FlexContainer, TextContainer, PageTitle } from './ui-elements';

const AutoplaySlider = withAutoplay(AwesomeSlider);

const SliderContainer = styled.div`
  ${tw`w-full pb-16 md:pb-4 md:w-2/3 md:pl-12`}
`;

const SpecialityTripTitle = tw(PageTitle)`md:text-center`;

type tripPriceObj = {
  tripName: string;
  tripPrice: number;
};

type Trip = {
  title: string;
  _rawBody: string;
  linkTxt?: string;
  linkUrl?: string;
  lightbox: {
    lightboxDisplay: string;
    slides: ImageSlide[];
  };
  tripPrice?: tripPriceObj[];
};

type ComponentProps = {
  trip: Trip;
};

function SpecialtyTrip({ trip }: ComponentProps) {
  const { title, _rawBody, lightbox, tripPrice, linkTxt, linkUrl } = trip;
  const singleImage = getImage(lightbox.slides[0].asset);

  const slider = (
    <AutoplaySlider
      play
      cancelOnInteraction
      interval={4000}
      id={lightbox.slides[0]._key}
    >
      {lightbox.slides.map((img: ImageSlide) => (
        <div key={img._key} data-src={getSrc(img.asset)} />
      ))}
    </AutoplaySlider>
  );

  const sliderContainer = (
    <SliderContainer>
      <div tw="w-full">
        {/* {lightbox.lightboxDisplay === 'display-all' &&
        lightbox.slides.length > 1 ? (
          slider
        ) : ( */}
        {singleImage && (
          <GatsbyImage
            image={singleImage}
            alt={lightbox.slides[0].alt}
            tw="rounded mb-8"
          />
        )}
        {/* )} */}
      </div>
    </SliderContainer>
  );

  return (
    <div tw="mb-4 mt-8">
      <SpecialityTripTitle tw="text-center">{title}</SpecialityTripTitle>
      <div tw="mb-8 text-center font-semibold italic">
        {tripPrice &&
          tripPrice.map((tp) => (
            <span tw="pr-4" key={tp.tripName}>
              {tp.tripName}: ${tp.tripPrice}
            </span>
          ))}
      </div>
      <FlexContainer>
        <TextContainer tw="md:w-1/2">
          <BlockContent blocks={_rawBody} />
        </TextContainer>
        {!lightbox || !lightbox.slides || lightbox.slides.length === 0 ? (
          <div />
        ) : (
          sliderContainer
        )}
      </FlexContainer>
    </div>
  );
}

export default SpecialtyTrip;
