import React from 'react';
import { graphql, PageProps } from 'gatsby';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import 'react-awesome-slider/dist/styles.css';
import '../../utils/awesome-slider-override.css';

import useSpecialtyTrips from '../../hooks/use-specialty-trips';
// import useCompanyInfo from '../../hooks/use-company-info';
import SpecialtyTrip from '../../components/specialty-trip';
import PageTemplate from '../../components/page-template';
import GuideSubNav from '../../components/guide-sub-nav';
import SEO from '../../components/seo';
// import CardContainer from '../components/card-container';
import { PageSection, TitleBar, PageTitle } from '../../components/ui-elements';

const SpecialityTripTitle = tw(PageTitle)`text-primary-100`;

type GraphQLData = {
  page: {
    seo: {
      title: string;
      description: string;
      keywords: string[];
    };
  };
};

type SpecialtyTripsPageProps = PageProps<GraphQLData>;

const SpecialtyTripsPage = (props: SpecialtyTripsPageProps) => {
  const { data } = props;
  const {
    page: { seo },
  } = data;
  const specialtyTrips = useSpecialtyTrips();

  return (
    <PageTemplate>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
      />
      <TitleBar>
        <SpecialityTripTitle>
          Specialty Guided Fishing Trips
        </SpecialityTripTitle>
      </TitleBar>
      <GuideSubNav activeLink="speciality-trip" />
      <PageSection>
        {specialtyTrips.map((trip) => (
          <SpecialtyTrip trip={trip} key={trip.title} />
        ))}
      </PageSection>
    </PageTemplate>
  );
};

export default SpecialtyTripsPage;

export const query = graphql`
  query SpecialtyTripsPageQuery {
    page: sanitySpecialtyTrips {
      seo {
        title
        description
        keywords
      }
    }
  }
`;
