import React from 'react';
import { Link } from 'gatsby';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

import { PageSection } from './ui-elements';

const SubNavWrapper = tw(
  PageSection
)`w-full bg-primary-400 p-4 text-gray-100 uppercase font-heading md:text-lg md:pt-2`;

type NavLinkProps = {
  to: string;
  label: string;
  active: boolean;
};

const NavLink = ({ to, label, active }: NavLinkProps) => (
  <Link
    to={to}
    className={`${active && 'is-active'} navbar-item is-tab`}
    activeClassName="is-active"
  >
    {label}
  </Link>
);

const GuideSubNav = ({ activeLink = '' }) => (
  <SubNavWrapper>
    <nav role="navigation" aria-label="sub navigation" tw="flex justify-center">
      <NavLink
        to="/guided-trips/"
        label="Overview"
        active={activeLink === 'overview'}
      />
      <NavLink
        to="/guided-trips/float-trips/"
        label="Float Trips"
        active={activeLink === 'float-trip'}
      />
      <NavLink
        to="/guided-trips/wade-trips/"
        label="Wade Trips"
        active={activeLink === 'wade-trip'}
      />
      <NavLink
        to="/guided-trips/specialty-trips/"
        label="Specialty Trips"
        active={activeLink === 'speciality-trip'}
      />
    </nav>
  </SubNavWrapper>
);

export default GuideSubNav;
