import { useStaticQuery, graphql } from 'gatsby';
import { ImageSlide } from '../entities/image-slide';

type tripPriceObj = {
  tripName: string;
  tripPrice: number;
};

type SpecialtyTrip = {
  title: string;
  _rawBody: string;
  linkTxt: string;
  linkUrl: string;
  lightbox: {
    lightboxDisplay: string;
    slides: ImageSlide[];
  };
  tripPrice: tripPriceObj[];
};

const useSpecialtyTrips = (): SpecialtyTrip[] => {
  const data = useStaticQuery(graphql`
    query {
      specialtyTrips: sanitySpecialtyTrips {
        trips {
          _key
          title
          _rawBody
          linkTxt
          linkUrl
          lightbox {
            lightboxDisplay
            slides {
              _key
              alt
              caption
              asset {
                gatsbyImageData(width: 600)
              }
            }
          }
          tripPrice {
            tripName
            tripPrice
          }
        }
      }
    }
  `);

  return data.specialtyTrips.trips;
};

export default useSpecialtyTrips;
